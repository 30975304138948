import { format } from '@msdyn365-commerce/retail-proxy';
import {
    IProductInventoryInformation
} from '@msdyn365-commerce-modules/retail-actions';

function getWeekNumber(date: Date): number {
    // Copy the given date so that we don't modify the original
    const currentDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

    // Set to the nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7 (ISO 8601 standard)
    const dayNumber = (currentDate.getUTCDay() + 6) % 7;

    // Thursday of the current week
    currentDate.setUTCDate(currentDate.getUTCDate() - dayNumber + 3);

    // First Thursday of the year
    const firstThursday = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 4));

    // Adjust if the first week starts in the previous year
    if (firstThursday.getUTCDay() !== 4) {
        firstThursday.setUTCDate(firstThursday.getUTCDate() - ((firstThursday.getUTCDay() + 6) % 7));
    }

    // Calculate full weeks to nearest Thursday
    const weekNumber = Math.ceil(((currentDate.getTime() - firstThursday.getTime()) / 86400000 + 1) / 7);

    return weekNumber > 0 ? weekNumber : 1;
}

export function formatString(template: string, ...values: any[]): string {
    if (!template) {
        return '';
    }
    return template.replace(/{(\d+)}/g, (match, index) => {
        const valueIndex = parseInt(index, 10);
        return values[valueIndex] !== undefined ? values[valueIndex] : match;
    });
}

export function addInventoryVisibilityInfo(
    labelToExpand: string,
    inventoryInformation: IProductInventoryInformation | undefined,
    expectedInventoryDateWithQuantityText: string | undefined,
    expectedInventoryDateText: string | undefined
): string {
    const expectedInventoryDate = inventoryInformation?.ProductAvailableQuantity.ExtensionProperties
        ?.find(x => x.Key === 'ATPDATE')?.Value?.DateTimeOffsetValue;

    // Not using this value for now while we're investgating incorrect values
    // const expectedInventoryQuantity = inventoryInformation?.ProductAvailableQuantity.ExtensionProperties
    //     ?.find(x => x.Key === 'ATPQTY')?.Value?.DecimalValue;

    const expectedInventoryQuantity = inventoryInformation?.ProductAvailableQuantity.AvailableQuantity;

    if (expectedInventoryDate) {
        /* const formattedDate = new Date(expectedInventoryDate).toLocaleDateString('nb-NO', {
            day: '2-digit',
            month: '2-digit'
        }); */
        const weekNumber = getWeekNumber(new Date(expectedInventoryDate));

        if (expectedInventoryQuantity) {
            const text = expectedInventoryDateWithQuantityText || '{0} forventet på lager i uke {1}';
            labelToExpand += `${labelToExpand ? '. ' : ''}${format(text, expectedInventoryQuantity, weekNumber)}`;
        } else {
            const text = expectedInventoryDateText || 'Forventet på lager i uke {0}';
            labelToExpand += `${labelToExpand ? '. ' : ''}${format(text, weekNumber)}`;
        }

        if (labelToExpand.endsWith('.')) {
            labelToExpand = labelToExpand.slice(0, -1);
        }
    }

    return labelToExpand;
}
